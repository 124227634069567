<template>
  <v-container>
    <v-card>
      <v-card-text>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="update">
            <v-row no-gutters class="text-center">
              <v-col cols="12" class="mb-4">
                <v-hover v-slot="{ hover }">
                  <v-avatar color="grey lighten-2" size="100">
                    <v-img
                      :src="imageSrc"
                      :max-width="imageDimensions.width"
                      :max-height="imageDimensions.height"
                    >
                      <v-fade-transition>
                        <v-overlay :value="hover" absolute>
                          <v-row align="center" justify="center">
                            <v-col cols="12">
                              <v-btn
                                icon
                                depressed
                                width="100"
                                height="100"
                                :ripple="false"
                                @click="pickFile"
                              >
                                <v-icon color="white" size="40">
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </v-avatar>
                </v-hover>

                <validation-provider
                  v-slot="{ errors }"
                  rules="size:5000"
                  :name="$t('profile_photo')"
                >
                  <v-file-input
                    style="visibility: hidden; height: 1px;"
                    v-model="files"
                    ref="image"
                    @change="previewImage($event)"
                  ></v-file-input>

                  <span class="red--text">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col cols="6" class="pr-2">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  :name="$t('last_name')"
                >
                  <v-text-field
                    type="text"
                    :label="$t('last_name')"
                    v-model="profile.last_name"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  :name="$t('first_name')"
                >
                  <v-text-field
                    type="text"
                    :label="$t('first_name')"
                    v-model="profile.first_name"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="6" class="pr-2">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  :name="$t('last_name_kana')"
                >
                  <v-text-field
                    type="text"
                    :label="$t('last_name_kana')"
                    v-model="profile.last_name_kana"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  :name="$t('first_name_kana')"
                >
                  <v-text-field
                    type="text"
                    :label="$t('first_name_kana')"
                    v-model="profile.first_name_kana"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  :name="$t('position')"
                >
                  <v-text-field
                    type="text"
                    :label="$t('position')"
                    v-model="profile.position"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|email|max:250"
                  :name="$t('email')"
                >
                  <v-text-field
                    type="text"
                    :label="$t('email')"
                    v-model="profile.email"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12">
                <v-btn type="submit" :disabled="invalid" color="primary" block>
                  {{ $t("update_profile") }}
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EditProfilePage",
  computed: {
    ...mapGetters(["profile"]),
    imageSrc() {
      // if (!this.profile.photo) {
      //   return require("@/assets/images/user.svg");
      // }

      return this.profile.photo;
    },
    imageDimensions() {
      let photoUrl = this.profile.photo;
      let photoFilename = photoUrl.substring(photoUrl.lastIndexOf("/") + 1);
      if (photoFilename == "avatar.svg") {
        return { width: "60px", height: "60px" };
      } else {
        return { width: "100%", height: "100%" };
      }
    }
  },
  data() {
    return {
      files: []
    };
  },
  created() {
    this.loading = true;
    this.$store.dispatch("PROFILE_GET").finally(() => (this.loading = false));
  },
  methods: {
    pickFile() {
      this.$refs.image.$refs.input.click();
    },
    previewImage(e) {
      this.profile.photo = URL.createObjectURL(e);
    },
    update() {
      this.$refs.observer.validate();

      this.loading = true;

      let formData = new FormData();
      formData.append("last_name", this.profile.last_name);
      formData.append("first_name", this.profile.first_name);
      formData.append("last_name_kana", this.profile.last_name_kana);
      formData.append("first_name_kana", this.profile.first_name_kana);
      formData.append("position", this.profile.position);
      formData.append("email", this.profile.email);
      formData.append("photo", this.files);

      this.$store.dispatch("EDIT_PROFILE", formData).then(result => {
        if (result.status === 200) this.$refs.observer.reset();
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped></style>
