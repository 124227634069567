var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.update($event)}}},[_c('v-row',{staticClass:"text-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-avatar',{attrs:{"color":"grey lighten-2","size":"100"}},[_c('v-img',{attrs:{"src":_vm.imageSrc,"max-width":_vm.imageDimensions.width,"max-height":_vm.imageDimensions.height}},[_c('v-fade-transition',[_c('v-overlay',{attrs:{"value":hover,"absolute":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","depressed":"","width":"100","height":"100","ripple":false},on:{"click":_vm.pickFile}},[_c('v-icon',{attrs:{"color":"white","size":"40"}},[_vm._v(" mdi-pencil ")])],1)],1)],1)],1)],1)],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"size:5000","name":_vm.$t('profile_photo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{ref:"image",staticStyle:{"visibility":"hidden","height":"1px"},on:{"change":function($event){return _vm.previewImage($event)}},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required|max:50","name":_vm.$t('last_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('last_name'),"error-messages":errors,"error":errors.length !== 0},model:{value:(_vm.profile.last_name),callback:function ($$v) {_vm.$set(_vm.profile, "last_name", $$v)},expression:"profile.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required|max:50","name":_vm.$t('first_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('first_name'),"error-messages":errors,"error":errors.length !== 0},model:{value:(_vm.profile.first_name),callback:function ($$v) {_vm.$set(_vm.profile, "first_name", $$v)},expression:"profile.first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required|max:50","name":_vm.$t('last_name_kana')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('last_name_kana'),"error-messages":errors,"error":errors.length !== 0},model:{value:(_vm.profile.last_name_kana),callback:function ($$v) {_vm.$set(_vm.profile, "last_name_kana", $$v)},expression:"profile.last_name_kana"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required|max:50","name":_vm.$t('first_name_kana')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('first_name_kana'),"error-messages":errors,"error":errors.length !== 0},model:{value:(_vm.profile.first_name_kana),callback:function ($$v) {_vm.$set(_vm.profile, "first_name_kana", $$v)},expression:"profile.first_name_kana"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|max:50","name":_vm.$t('position')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('position'),"error-messages":errors,"error":errors.length !== 0},model:{value:(_vm.profile.position),callback:function ($$v) {_vm.$set(_vm.profile, "position", $$v)},expression:"profile.position"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|email|max:250","name":_vm.$t('email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('email'),"error-messages":errors,"error":errors.length !== 0},model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", $$v)},expression:"profile.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","disabled":invalid,"color":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t("update_profile"))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }